var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "Login base-height" }, [
      _c("div", { staticClass: "login_left" }),
      _c("div", { staticClass: "mainBox" }, [
        _c(
          "h1",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/reviewmind/logo2.png"),
                  alt: "로고"
                }
              })
            ])
          ],
          1
        ),
        _c("h2", [_vm._v(_vm._s(_vm.$t("findId")))]),
        _c("div", { staticClass: "form login" }, [
          _c("fieldset", [
            _c("legend", [_vm._v("로그인")]),
            _c("div", { staticClass: "cover bg" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.username,
                    expression: "username"
                  }
                ],
                attrs: { type: "text", placeholder: _vm.$t("username") },
                domProps: { value: _vm.username },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.username = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "cover bg" }, [
              _c("div", { staticClass: "phone" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mobileNo,
                      expression: "mobileNo"
                    }
                  ],
                  attrs: { type: "text", placeholder: _vm.$t("findId_mobile") },
                  domProps: { value: _vm.mobileNo },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mobileNo = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "cover bg" }, [
              _c(
                "button",
                {
                  staticClass: "btn primary findEmail",
                  attrs: { type: "button" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" " + _vm._s(_vm.$t("findId")) + " ")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }